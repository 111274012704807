<template>
  <!-- <main-wrapper :tool="false"> -->

    <div class="v-wrapper tool--none">
      <div class="v-body">
        <template>
          <ul class="modeOption">
              <li class="active"><span><img :src="require('../../assets/images/gs-mn11.png')" alt=""></span>{{$t('commonListVehicle.typeAll')}}</li>
              <li @click="$emit('display-change', 1)"><span><img :src="require('../../assets/images/gs-mn21.png')" alt=""></span>{{$t('commonListVehicle.typeVehicle')}}</li>
              <li @click="$emit('display-change', 2)"><span><img :src="require('../../assets/images/gs-mn31.png')" alt=""></span>{{$t('commonListVehicle.typePerson')}}</li>
              <!-- <li @click="$emit('display-change', 3)"><span><i-icon name="iconxinxiguanli_wupin_xuanzhong"></i-icon> </span>{{$t('commonListVehicle.typeAirtcle')}}</li> -->
          </ul>
          <div class="search-bars">
            <!-- <tool-tip :content="$t('deviceManage.delete')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="deleteHandle" v-if="loginInfo.allFuns.includes(5043)" circle v-ripple>
                <i-icon name="iconshanchu"></i-icon>
              </el-button>
            </tool-tip>
            <tool-tip :content="$t('deviceManage.export')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain :loading="exportButtonLoading" @click="exportHandle" circle v-ripple>
                 <i-icon name="iconxinxiguanli_daochu_weixuanzhong" v-if="!exportButtonLoading"></i-icon>
              </el-button>
            </tool-tip>
            <tool-tip :content="$t('deviceManage.changeParent')" placement="top" type="light-tip" :offset="-10">
              <el-button type="primary" plain @click="rowChange($refs.multipleTable.selection, false)" v-if="loginInfo.allFuns.includes(5042)" circle v-ripple>
                <i-icon name="iconxinxiguanli_qianyi_weixuanzhong"></i-icon>
              </el-button>
            </tool-tip> -->

            <div class="e-radio"  @click="deleteHandle"  type="primary" v-if="loginInfo.allFuns.includes(5043)">删除</div>
            <div class="e-radio" @click="exportHandle" v-loading="exportButtonLoading">导出</div>
            <div class="e-radio" @click="rowChange($refs.multipleTable.selection, false)" v-if="loginInfo.allFuns.includes(5042)">迁移</div>
            <div class="e-radio" @click="openBatchCommand($refs.multipleTable.selection)" v-if="loginInfo.allFuns.includes(20121)">指令</div>
      
            <search-group :options="options" @click="click" style="float:right;" @input="({select,value,Op}) => {searchName=select;searchText=value;searchOption=Op}"
              :field="{select: searchName, value: searchText}" multiple-mode multiple-key="D.SIM2"></search-group>

            <span class="iml-1" style="float:right;font-size:14px;line-height:32px;">{{$t('deviceManage.status')}}:
              <el-select v-model="wxOptionValue" style="width:120px;" v-if="isShowWxActive" size="small">
                <el-option v-for="item in wxOptions" :key="item.label" :value="item.value" :label="item.label"></el-option>
              </el-select>
              <el-select v-model="queryKey2" style="width:120px;margin-left:4px" size="small">
                <el-option v-for="item in options2" :key="item.label" :value="item.value" :label="item.label"></el-option>
              </el-select>
            </span>
            <span class="iml-1" style="float:right;font-size:14px;line-height:32px;">
              <el-select v-model="queryTrackerType" style="width:120px;" size="small">
                <el-option v-for="item in trackerTypeOptions" :key="item.label" :value="item.value" :label="$t('dict')[item.label]"></el-option>
              </el-select>
            </span>
            <el-checkbox v-model="hasChild" class="search-containChild" style="margin-left:5px;">{{$t('deviceManage.includeChild')}}</el-checkbox>
            <el-checkbox v-if="!isShowWxActive" v-model="hideTip" class="search-containChild imr-2" style="margin-left:5px;">{{$t('deviceManage.wrap')}}</el-checkbox>
            <el-checkbox v-model="isShowMore" class="search-containChild" style="margin-right:5px;">{{$t('deviceManage.displayMore')}}</el-checkbox>
            <el-checkbox v-model="hideTip" v-if="isShowWxActive" class="search-containChild" style="margin-right:10px;">{{$t('deviceManage.wrap')}}</el-checkbox>
            <!-- <template v-if="isShowWxActive">
              <el-popover trigger="click" placement="bottom">
                <el-button slot="reference" type="text" style="float:right;margin-right:16px;font-size:16px;"><i class="el-icon-setting" style="color:#999;"></i></el-button>
                <div>
                  <div><el-checkbox v-model="hideTip" class="search-containChild imr-2" style="margin-left:0;margin-top: 4px;">{{$t('deviceManage.wrap')}}</el-checkbox></div>
                </div>
              </el-popover>
            </template> -->
          </div>

          <el-table :data="tableData" class="v-table" border @sort-change="sortChange" @header-dragend="saveHeaderDragWidth" :height="tableHeight2" size="mini" ref="multipleTable" v-loading="tableLoading" :element-loading-text="tableLoadingText">
            <el-table-column :key="10" type="selection" width="55" :selectable="selectableHandle"></el-table-column>
            <el-table-column :key="20" sortable="custom" prop="SIM2" :label="$t('deviceManage.deviceID')" :width="TW($t('deviceManage.deviceID'), 180)" :show-overflow-tooltip="false">
              <div style="white-space:nowrap;" slot-scope="{row}">
                <add-task-car @click="addToTaskList(row)" :types="row.TypeID"></add-task-car>
                <img src="../../assets/icon/vip.png" class="vip" v-if="row.isVip"/>
                <device-popover :item-id="row.ObjectID">
                  <span class="i-link" @click.stop="rowClick(row)">{{ row.SIM2|emptyFormat }}</span>
                </device-popover>
              </div>
            </el-table-column>
            <!-- <el-table-column :key="25" sortable="custom" prop="TrackerTypeName" :width="TW($t('deviceManage.TrackerTypeName'), 90)" :label="$t('deviceManage.TrackerTypeName')">
              <span slot-scope="{row}">{{ $t('dict')[row.TrackerTypeName] }}</span>
            </el-table-column> -->
            <el-table-column :key="30" sortable="custom" prop="VehicleNum" class-name="vehicle-name" :label="$t('deviceManage.deviceName')" :width="TW($t('deviceManage.vehicleName'), 120)" :show-overflow-tooltip="!hideTip">
              <div slot-scope="{row}">
                <template v-if="row.TypeID===0">
                    <!-- @click.stop="$refs.vehicleDetail.open(row.VehicleID, {})" -->
                    <vehicle-name  v-if="row.VehicleID" :color="row.VehicleNumBackColor" :data-id="row.VehicleID" :title="`${row.VehicleName}`">{{ row.VehicleName }}</vehicle-name>
                    <span v-else class="i-link" @click="bindVehicle(row)"><i class="el-icon-search"></i> {{$t('common.queryAndBind')}}</span>
                </template>
                <template v-else>
                    <span class="i-over">{{row.VehicleName}}</span>
                </template>
              </div>
            </el-table-column>
            <el-table-column :key="40" sortable="custom" prop="SIM" :label="$t('deviceManage.sim')" :width="TW($t('deviceManage.sim'), 120)" v-if="isShowMore" :show-overflow-tooltip="!hideTip">
              <span slot-scope="{row}">

                <span v-if="row.ExpiredDate && $i18n.locale==='zh'">
                  <img :src="getSimImg(row.ExpiredDate, row.RemainMoney)" class="simImg" @click="openSimDetail(row.SIM)" v-tooltip="$t('deviceManage.clickViewSIM')"/>
                </span>
                <span :title="row.ObjectID" @click="rowClickInfo(row)" class="sim i-link" v-tooltip="$t('deviceManage.clickViewDetail')">
                {{row.SIM}}
                </span>
              </span>
            </el-table-column>
            <el-table-column :key="50" sortable="custom" prop="MDTModelName" :label="$t('deviceManage.deviceType')" :width="TW($t('deviceManage.deviceType'), 100)" v-if="false">
              <template slot-scope="{row}">{{row.MDTModelName}}</template>
            </el-table-column>
            <el-table-column :key="55" sortable="custom" prop="Factory" :label="$t('deviceManage.mdtType')" :width="TW($t('deviceManage.mdtType'), 100)" v-if="false">
            </el-table-column>
            <el-table-column :key="60" sortable="custom" prop="PayEndDate" :label="$t('deviceManage.vip')" :width="TW($t('deviceManage.vip'), 120)">
              <template slot-scope="{row}">
                <p v-if="!row.PayEndDate">--</p>
                <p v-else-if="miment(row.PayEndDate).getTime() <= miment().getTime()">{{$t('deviceManage.optionKey12')}}</p>
                <p v-else-if="miment(row.PayEndDate).getTime() < miment().add(90, 'DD').getTime()" style="color:#f44336;">{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
                <p v-else>{{miment(row.PayEndDate).format("YYYY-MM-DD")}}</p>
              </template>
            </el-table-column>
            <el-table-column :key="70" prop="StatusDes" :label="$t('deviceManage.status')" :width="TW($t('deviceManage.status'), 300)" :show-overflow-tooltip="!hideTip">
              <span slot-scope="{row}" class="statusdes" @click="$router.push(`/online/track?id=${row.ObjectID}`)" :title="row.StatusDes">{{row.StatusDes}}</span>
            </el-table-column>
            <el-table-column :key="80" sortable="custom" prop="isOnline" :label="$t('deviceManage.online')" :width="TW($t('deviceManage.online'), 75)">
              <template slot-scope="{row}">
                <p class="square" :class="{isOnline: !!row.isOnline}"></p>
                <p class="imgcolumn" v-if="false"><img src="../../assets/icon/在线.gif" alt="在线" :title="$t('deviceManage.keyOnline')"/></p>
                <p class="imgcolumn" v-if="false"><img src="../../assets/icon/离线1.gif" alt="离线" :title="$t('deviceManage.keyOffline')"/></p>
              </template>
            </el-table-column>
            <el-table-column :key="90" sortable="custom" prop="Status" :label="$t('deviceManage.acc')" :width="TW($t('deviceManage.acc'), 75)">
              <template slot-scope="{row}">
                <template v-if="row.TypeID===0">
                   <p class="imgcolumn" :class="row.tagClass">
                        <template v-if="row.tagClass==1">
                          <span class="status" alt="启动,行驶" :title="$t('deviceManage.keyOnRoad')">
                              <i class="s1"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==2">
                          <span class="status" alt="熄火" :title="$t('deviceManage.keyNoSpeed')">
                              <i class="s4"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==3">
                          <span class="status" alt="启动,怠速" :title="$t('deviceManage.keyDaisu')">
                              <i class="s2"></i>
                          </span>
                        </template>
                          <template v-if="row.tagClass==4">
                          <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                              <i class="s6"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==6">
                          <template v-if="row.Status">
                            <span class="status" alt="启动,参考定位" :title="$t('deviceManage.keyRef')">
                                <i class="s3"></i>
                            </span>
                          </template> 
                           <template v-else>
                             <span class="status" alt="熄火,参考定位" :title="$t('deviceManage.keyNoSpeedRef')">
                              <i class="s5"></i>
                             </span>
                          </template> 
                        </template>                   
                     </p>
                </template> 
                <template v-else>
                   <p class="imgcolumn" :class="row.tagClass">
                        <template v-if="row.tagClass==4">
                          <span class="status" alt="离线" :title="$t('deviceManage.keyOffRef')">
                              <i class="s6"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==1">
                          <span class="status" alt="运动" :title="$t('deviceManage.keyMov')">
                              <i class="s1"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==3">
                          <span class="status" alt="静止" :title="$t('deviceManage.keyStop')">
                              <i class="s2"></i>
                          </span>
                        </template>
                        <template v-if="row.tagClass==6">
                          <span class="status" alt="参考定位" :title="$t('deviceManage.keyNoGps')">
                              <i class="s2"></i>
                          </span>
                        </template>                   
                     </p>
                </template> 
              </template>
            </el-table-column>
            <el-table-column :key="100" sortable="custom" prop="isAlarm" :label="$t('deviceManage.alarm')" :width="TW($t('deviceManage.alarm'), 75)">
              <template slot-scope="{row}">
                <p v-if="!!row.isAlarm" class="imgcolumn"><img src="../../assets/icon/报警.gif" alt="报警" :title="$t('deviceManage.keyAlarm')"/></p>
                <p v-else class="imgcolumn"><img src="../../assets/icon/正常.gif" alt="正常" :title="$t('deviceManage.keyNormarl')"/></p>
              </template>
            </el-table-column>
            <el-table-column :key="105" sortable="custom" prop="GPSTime" :label="$t('deviceManage.gpsTime')" :width="TW($t('deviceManage.gpsTime'), 150)" v-if="isShowMore">
              <p slot-scope="{row}">{{row.GPSTime ? miment(row.GPSTime).format() : ''}}</p>
            </el-table-column>
            <el-table-column :key="110" sortable="custom" prop="RcvTime" :label="$t('deviceManage.rcvTime')" :width="TW($t('deviceManage.rcvTime'), 150)">
              <p slot-scope="{row}">{{row.RcvTime ? miment(row.RcvTime).format() : ''}}</p>
            </el-table-column>
            <el-table-column :key="120" sortable="custom" prop="OfflineDesc" :label="$t('deviceManage.offlineTime')" :width="TW($t('deviceManage.offlineTime'), 150)" :show-overflow-tooltip="!hideTip"></el-table-column>
            <el-table-column :key="130" :label="$t('deviceManage.place')"  v-if="isShowMore" :width="TW($t('deviceManage.place'), 180)" :show-overflow-tooltip="!hideTip">
              <template slot-scope="{row}">
                <span-address :lon="row.Lon" :lat="row.Lat"></span-address>
              </template>
            </el-table-column>
            <el-table-column :key="140" sortable="custom" prop="CreateTime"  v-if="isShowMore" :label="$t('deviceManage.createTime')" :width="TW($t('deviceManage.createTime'), 150)">
              <p slot-scope="{row}">{{miment(row.CreateTime).format()}}</p>
            </el-table-column>
            <el-table-column :key="150" prop="Remark"  v-if="isShowMore" :label="$t('deviceManage.remark')" :width="TW($t('deviceManage.remark'), 180)" :show-overflow-tooltip="!hideTip"></el-table-column>
            <el-table-column :key="160" label="" :resizable="false" min-width="95px">
              <template slot-scope="{row}">
                <i-drop-down trigger="click">
                  <span slot="active" class="i-link">{{$t('common.operate')}}<i class="el-icon-caret-bottom" style="margin-left:0.5em;"></i></span>
                  <i-drop-down-option :label="$t('deviceManage.place')" value="5" @click="goMonitor(row)" ></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.modify')" value="0" @click="rowClick(row)"></i-drop-down-option>
                  <i-drop-down-option :label="$t('common.migrate')" value="3" @click="rowChange([row],false)" v-if="loginInfo.allFuns.includes(5042)"></i-drop-down-option>
                  <!-- <i-drop-down-option :label="$t('deviceManage.updateVip')" value="3" @click="() => singlePay(row)" v-if="loginInfo.allFuns.includes(20141)"></i-drop-down-option> -->
                  <!-- <i-drop-down-option :label="$t('deviceManage.trace')" value="3" @click="() => gotoReport(row)" ></i-drop-down-option> -->
                  <i-drop-down-option :label="$t('common.delete')" value="4" @click="deleteHandle([row])" v-if="loginInfo.allFuns.includes(5043)"></i-drop-down-option>
                </i-drop-down>
              </template>
            </el-table-column>
          </el-table>
          <div class="deviceTableTips">
            <ul class="online">
              <li class="on"><span></span>{{$t('deviceManage.online')}}</li>
              <li class="off"><span></span>{{$t('deviceManage.offline')}}</li>
            </ul>
            <ul class="gps">
              <li class="state1"><span></span>{{$t('deviceManage.optionKey4')}}</li>
              <li class="state2"><span></span>{{$t('deviceManage.optionKey5')}}</li>
              <li class="state3"><span></span>{{$t('deviceManage.optionKey16')}}</li>
              <li class="state4"><span></span>{{$t('deviceManage.optionKey17')}}</li>
            </ul>
          </div>

          <el-pagination background class="v-pager" :page-size="pageSize" :page-sizes="pageSizes2" :pager-count="11" :current-page="pageIndex" layout="total, sizes, prev, pager, next, jumper"
            :total="pageTotalCount" @current-change="pageCurrentChange" @size-change="pageSizeChange" popper-class="custom">
          </el-pagination>

          <vcl-code v-if="false" class="ima-4" :width="400" :height="150"></vcl-code>

          <transition name="scale-grow" @before-enter="isAnimating=true" @before-leave="isAnimating=true" @after-enter="isAnimating=false" @after-leave="isAnimating=false">
            <router-view @refresh="refreshHandle"></router-view>
          </transition>

          <el-dialog width="400px" :title="$t('deviceManage.updateVip')" :visible.sync="dialogSinglePay" custom-class="v-dialog v-dialog--wallet" top="5vh">
            <single-pay v-bind="single" v-if="dialogSinglePay" @close="() => dialogSinglePay = false" @go-wallet="goWallet"></single-pay>
          </el-dialog>

          <el-dialog :title="$t('deviceManage.moverDevices')" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding v-gs-dialog" :visible.sync="dialogChangeParent">
            <change-parent :datas="changedRow" batch-input v-if="dialogChangeParent" :old-hold-info="{id:currentHoldId,name:currentHoldName}" mode="device" @close="dialogChangeParent=false"></change-parent>
          </el-dialog>

          <detail-device ref="detail" @refresh="refreshHandle"></detail-device>

          <detail-device-object ref="detailObject" @refresh="refreshHandle"></detail-device-object>

          <detail-device-person ref="detailPerson" @refresh="refreshHandle"></detail-device-person>

          <import-tool ref="importTool" :download-url="$t('batchImport.fileName2')">
            <div class="importOptions" slot="options" v-if="loginInfo.allFuns.includes(20142)">
              <label>{{$t('deviceManage.vip')}}</label>
              <el-select v-model="importVipMonth" class="iml-1" style="width: 120px;" size="small">
                <el-option :label="$t('deviceManage.oneYear')" :value="12"></el-option>
                <el-option :label="$t('deviceManage.twoYear')" :value="24"></el-option>
                <el-option :label="$t('deviceManage.threeYear')" :value="36"></el-option>
                <el-option :label="$t('deviceManage.fourYears')" :value="48"></el-option>
                <el-option :label="$t('deviceManage.fiveYears')" :value="60"></el-option>
                <el-option label="--" :value="0"></el-option>
              </el-select>
              <label class="iml-1">{{$t('deviceManage.effectiveWay')}}</label>
              <el-select v-model="importVipOption" class="iml-1" style="width: 200px;" size="small">
                <el-option :label="$t('deviceManage.effectiveNow')" :value="1"></el-option>
                <el-option :label="$t('deviceManage.effectiveDelay')" :value="2"></el-option>
                <!-- <el-option :label="$t('deviceManage.effectiveOnActive')" :value="3"></el-option> -->
              </el-select>
            </div>
          </import-tool>
          <import-detail :items="excelItems" ref="importDetail" :download-method="excelDownLoadMethod" :progress="excelProgressMethod" @closed="queryHandle"></import-detail>

          <el-dialog :title="$t('updateVip.wallet')" append-to-body width="640px" :visible.sync="dialogInfoVisible" custom-class="v-dialog v-dialog--wallet">
            <wallet-info v-if="dialogInfoVisible" @close="() => dialogInfoVisible = false" @send="send"></wallet-info>
          </el-dialog>

          <bind-vehicle ref="bindVehicle" @refresh="queryHandle"></bind-vehicle>

          <el-dialog :title="$t('updateVip.sendVPoint')" append-to-body :visible.sync="dialogSendVisible" custom-class="v-dialog v-dialog--sm v-dialog--mdpadding">
            <wallet-send v-if="dialogSendVisible" @close="() => dialogSendVisible = false"></wallet-send>
          </el-dialog>

          <detail-vehicle ref="vehicleDetail" @refresh="queryHandle"></detail-vehicle>

          <detail-device-info ref="detailDeviceInfo" :item-type="deviceType"></detail-device-info>
          <!-- 指令发送 弹窗 -->
          <el-dialog :visible.sync="isShowDialogBatch" :title="$t('onlineMonitor.batchCommand')"
            custom-class="v-dialog v-dialog--md v-dialog--mdpadding" append-to-body v-if="isShowDialogBatchReal"
            @closed="isShowDialogBatchReal = false">
            <batch-command :current-type="currentCommandTracker" :current-command-default="currentCommandDefault"
              :checked-object-keys-default="checkedObjectKeysDefault" :current-command-single="currentCommandSingle"></batch-command>
          </el-dialog>
          <el-dialog :custom-class="iframe.class" :visible.sync="isShowDialog" top="5vh" :width="iframe.width" :title="iframe.title" @closed="closedIframe" append-to-body v-if="isShowDialogReal">
            <iframe frameborder="0" class="mine" :height="iframe.height" width="100%" scrolling="yes" :src="iframe.src"></iframe>
          </el-dialog>
        </template>
      </div>
    </div>
  <!-- </main-wrapper> -->
</template>

<script>
import { VclCode } from 'vue-content-loading'
import SearchGroup from './components/search-group'
import IDropDown from '@/base/i-dropdown/i-dropdown'
import IDropDownOption from '@/base/i-dropdown/i-dropdown-option'
import mixin from './mixin'
import * as API from '@/api/device'
import {Filter, Op} from '@/api/config'
import TreeUser from '@/views/common/tree-user'
import SinglePay from '@/views/wallet/single-pay'
import miment from 'miment'
import DetailDevice from './detail/detail-device'
import DetailDevicePerson from './detail/detail-device.person'
import DetailDeviceObject from './detail/detail-device.object'
import ImportTool from './components/import-tool'
import ImportDetail from './components/import-detail'
import { saveBlob ,translateGps} from '@/common/utils'
import AddTaskCar from '@/views/common/add-task-car'
import {mapMutations} from 'vuex'
import WalletInfo from '@/views/wallet/wallet-info'
import WalletSend from '@/views/wallet/wallet-send'
import DevicePopover from '@/views/common/device-popover'
import BindVehicle from '@/views/manage/detail/bind-vehicle'
import DetailVehicle from './detail/detail-vehicle'
import DetailDeviceInfo from './detail/detail-device-info'
import BatchCommand from '@/views/batch/batch-command'
import {GetSimInfoUrl} from '@/api/common'
import storage from 'good-storage'
import {S_NEXT_REPORT_PARAM, setShowMore, getShowMore} from '@/common/cache'

export default {
  name: 'manageDevice',
  data() {
    return {
      miment,
      hideTip: false,
      deviceType:0,
      options: [
        { label: '设备ID', value: 'D.SIM2', Op: Op.Contains },
        { label: 'SIM卡号', value: 'D.SIM', Op: Op.Contains },
        { label: '状态', value: 'A.StatusDes', Op: Op.Contains },
        { label: '备注', value: 'D.Remark', Op: Op.Contains },
      ],
      options2: [
        { label: '所有', value: '所有' },
        { label: '在线', value: '在线' },
        { label: '离线', value: '离线' },
        { label: '启动', value: '启动' },
        { label: '熄火', value: '熄火' },
        { label: '报警', value: '报警' },
        { label: '无报警', value: '无报警' },
        { label: 'SIM卡正常', value: 'SIM卡正常' },
        { label: 'SIM卡即将到期', value: 'SIM卡即将到期' },
        { label: 'SIM卡已到期', value: 'SIM卡已到期' },
        { label: 'SIM卡余额不多', value: 'SIM卡余额不多' },
        { label: '服务已到期', value: '服务已到期' },
        { label: '服务7天到期', value: '服务7天到期' },
        { label: '服务30天到期', value: '服务30天到期' },
        { label: '服务60天到期', value: '服务60天到期' },
      ],
      queryKey2: '所有',
      isAnimating: false, // 是否正在动画
      dialogSinglePay: false,
      isShowDialog: false,
      isShowDialogReal: false,
      single: {
        id: null,
        vehicleName: null,
        serviceEndDate: null
      },
      excelItems: [
        { label: '行号', prop: 'Index' },
        { label: '设备ID', prop: 'SIM2' },
        { label: 'SIM卡号', prop: 'SIM' },
        { label: '设备型号', prop: 'MDTModel' },
        { label: '接线类型', prop: 'TrackerTypeName' },
        { label: '备注', prop: 'Remark' },
        { label: '导入结果', prop: 'errDesc' },
        { label: '导入详情', prop: 'errMsg' }
      ],
      excelDownLoadMethod: null,
      excelProgressMethod: null,
      dialogInfoVisible: false,
      dialogSendVisible: false,
      iframe: {
        title: '',
        class: '',
        width: '920px',
        height: '700px',
        src: '#'
      },
      trackerTypeOptions: [
        { label: '全部', value: null },
        { label: '有线', value: 0 },
        { label: '无线', value: 1 }
      ],
      queryTrackerType: null,
      wxOptionValue: '全部(WP)',
      wxOptions: [
        { label: this.$t('deviceManage.statusKey1'), value: '全部(WP)' },
        { label: this.$t('deviceManage.statusKey2'), value: '未激活(WP)' },
        { label: this.$t('deviceManage.statusKey3'), value: '正常(WP)' },
        { label: this.$t('deviceManage.statusKey4'), value: '停机(WP)' },
        { label: this.$t('deviceManage.statusKey5'), value: '离线(WP)' }
      ],
      isShowWxActive: true,
      importVipMonth: 0,
      importVipOption: 1,
      isShowDialogBatch: false, // 发送指令弹窗
      isShowDialogBatchReal: false,
      currentCommandDefault: 10, // 批量指令默认指令类型
      checkedObjectKeysDefault: [], // 批量指令默认设备
    }
  },
  components: {
    AddTaskCar,
    SinglePay,
    IDropDown,
    IDropDownOption,
    SearchGroup,
    VclCode,
    TreeUser,
    DetailDevice,
    ImportTool,
    ImportDetail,
    WalletInfo,
    WalletSend,
    DevicePopover,
    BindVehicle,
    DetailVehicle,
    DetailDeviceInfo,
    BatchCommand,
    DetailDevicePerson,
    DetailDeviceObject
  },
  mixins: [
    mixin
  ],
  created() {
    this.miment = miment
    this.searchName = this.options[0].value
    this.isShowMore = getShowMore(false)
    this.getOptions()
    this.pageIndex = 1
    this.queryHandle()
  },
  watch: {
    '$i18n.locale': 'getOptions',
    isShowMore(val) {
      this.$nextTick(()=>{ this.$refs.multipleTable.doLayout() })
      // setTimeout(() => {
      //   this.$refs.multipleTable.doLayOut()
      // }, 25)
      setShowMore(val)
    }
  },
  methods: {
    openBatchCommand(rows) {
      if (!rows || rows.length === 0) {
        this.$message({
          message: this.$t('common.emptySelectedTip')
        })
        return
      }
      let list = rows.map(k=> { return k.ObjectID})
      this.checkedObjectKeysDefault = list
      this.currentCommandDefault = 10
      this.currentCommandTracker = 1
      this.isShowDialogBatch = true
      this.isShowDialogBatchReal = true
      this.currentCommandSingle = false
    },
    click() {
      this.pageIndex = 1
      this.queryHandle()
    },
    getOptions() {
      this.options = [
        { label: this.$t('deviceManage.deviceID'), value: 'D.SIM2', Op: Op.Contains },
        { label: this.$t('deviceManage.sim'), value: 'D.SIM', Op: Op.Contains },
        { label: this.$t('deviceManage.deviceName'), value: 'VehicleNum', Op: Op.Contains },
        { label: this.$t('deviceManage.status'), value: 'A.StatusDes', Op: Op.Contains },
        { label: this.$t('deviceManage.remark'), value: 'D.Remark', Op: Op.Contains }
      ]
      this.options2 = [
        { label: this.$t('deviceManage.optionKey1'), value: '所有' },
        { label: this.$t('deviceManage.optionKey2'), value: '在线' },
        { label: this.$t('deviceManage.optionKey3'), value: '离线' },
        { label: this.$t('deviceManage.optionKey4'), value: '启动' },
        { label: this.$t('deviceManage.optionKey5'), value: '熄火' },
        { label: this.$t('deviceManage.optionKey6'), value: '报警' },
        { label: this.$t('deviceManage.optionKey7'), value: '无报警' },
        { label: this.$t('deviceManage.optionKey8'), value: 'SIM卡正常' },
        { label: this.$t('deviceManage.optionKey9'), value: 'SIM卡即将到期' },
        { label: this.$t('deviceManage.optionKey10'), value: 'SIM卡已到期' },
        { label: this.$t('deviceManage.optionKey11'), value: 'SIM卡余额不多' },
        { label: this.$t('deviceManage.optionKey12'), value: '服务已到期' },
        { label: this.$t('deviceManage.optionKey13'), value: '服务7天到期' },
        { label: this.$t('deviceManage.optionKey14'), value: '服务30天到期' },
        { label: this.$t('deviceManage.optionKey15'), value: '服务60天到期' },
      ]

      this.wxOptions = [
        { label: this.$t('deviceManage.statusKey1'), value: '全部(WP)' },
        { label: this.$t('deviceManage.statusKey2'), value: '未激活(WP)' },
        { label: this.$t('deviceManage.statusKey3'), value: '正常(WP)' },
        { label: this.$t('deviceManage.statusKey4'), value: '停机(WP)' },
        { label: this.$t('deviceManage.statusKey5'), value: '离线(WP)' }
      ]

      this.excelItems = [
        { label: '行号', prop: 'Index' },
        { label: '设备ID', prop: 'SIM2' },
        { label: 'SIM卡号', prop: 'SIM' },
        { label: '设备型号', prop: 'MDTModel' },
        { label: '接线类型', prop: 'TrackerTypeName' },
        { label: '备注', prop: 'Remark' },
        { label: '导入结果', prop: 'errDesc' },
        { label: '导入详情', prop: 'errMsg' },
      ].map(k => ({ label: this.$t('deviceManage')[k.prop] || k.label, prop: k.prop }))
    },
    rowClick(row) {
      // this.$router.push({
      //   name: 'deviceDetail',
      //   params: {
      //     itemId: row.ObjectID + ''
      //   }
      // })

      if(row.TypeID===1){
        this.$refs.detailPerson.open(row.ObjectID)
      }else if(row.TypeID===2){
        this.$refs.detailObject.open(row.ObjectID)
      }else{
        this.$refs.detail.open(row.ObjectID)
      }

      //this.$router.push(`/manage/device/detail?id=${row.ObjectID}`)
    },
    rowClickInfo(row) {
      this.deviceType = row.TypeID
      this.$refs.detailDeviceInfo.open(row.ObjectID,row)
    },
    selectableHandle() {
      return true
      //return row.UserID != this.loginInfo.UserID
    },
    addHandle() {
      if(row.TypeID===1){
        this.$refs.detailPerson.open()
      }else if(row.TypeID===2){
        this.$refs.detailObject.open()
      }else{
        this.$refs.detail.open()
      }
      //this.$router.push('/manage/device/detail')
    },
    deleteHandle(rows) {
      if (!(rows instanceof Array)) {
        rows = null
      }
      const targetRows = rows || this.$refs.multipleTable.selection
      if (targetRows.length <= 0) {
        this.$alert(this.$t('common.emptySelectedTip'), '', {
          type: 'error'
        })
        return
      }

      let msg0 = this.$t('common.deleteTip')
      this.$confirm(msg0, this.$t('common.warn'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {

        this.deleteButtonLoading = true
        API.Delete(targetRows.map(k => k.ObjectID)).then(() => {
          // 从本地删除
          // targetRows.forEach(item => {
          //   deleteFromArray(this.tableData, row => {
          //     return row == item
          //   })
          // })

          this.$message({
            type: 'success',
            message: this.$t('common.deleteSuccessed'),
            showClose: true
          });

          if ((targetRows.length < this.pageSize)
            && (targetRows.length < this.tableData.length)) {
            this.pageIndex = this.pageIndex > 1 ? this.pageIndex - 1 : 1
          }
          this.pageIndex = 1
          this.queryHandle()

        }).catch(err => {
          this.print('error', err.message)
        })

      }).catch(() => {

      });
    },
    queryHandle() {
      const promise = new Promise((resolve, reject) => {
        const filters = []

        this.searchText.length > 0
          && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
        this.getExternalFilters(filters)
        this.getExternalFilters2(filters)
        if (this.queryTrackerType !== null) {
          filters.push(new Filter('D.TrackerType', this.queryTrackerType, Op.Equals))
        }
        if(this.queryKey2=='熄火'){
           filters.push({PropertyName: "A.Speed", Value: 0, Operation: 0, Id: null})
        }
        this.tableLoading = true
        API.Query(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, this.currentHoldId, this.hasChild).then(ret => {
          // console.log(ret)
          this.tableData = ret.data.list.slice()
          this.tableData.map(k => {
            k.tagClass = translateGps(k.TypeID,k,false)
          })
          this.pageTotalCount = ret.data.total
          this.tableLoading = false
          resolve()
        }).catch(err => {
          this.print('error', err.message)
          this.tableLoading = false
          reject(err)
        })
      })
      return promise
    },
    getExternalFilters2(filters) {
      if (this.wxOptionValue == '所有') {
        return filters
      }

      if (this.wxOptionValue == '未激活(WP)') {
        filters.push(new Filter(' (CASE WHEN WX.ObjectID IS NULL THEN \'未激活\' WHEN PayEndDate > GETDATE() AND A.RcvTime > DATEADD(DAY, -1, GETDATE()) THEN \'正常\' WHEN PayEndDate IS NULL OR PayEndDate < GETDATE() THEN \'停机\' ELSE \'离线\' END) ', '未激活', Op.Equals))
      } else if (this.wxOptionValue == '正常(WP)') {
        filters.push(new Filter(' (CASE WHEN WX.ObjectID IS NULL THEN \'未激活\' WHEN PayEndDate > GETDATE() AND A.RcvTime > DATEADD(DAY, -1, GETDATE()) THEN \'正常\' WHEN PayEndDate IS NULL OR PayEndDate < GETDATE() THEN \'停机\' ELSE \'离线\' END) ', '正常', Op.Equals))
      } else if (this.wxOptionValue == '停机(WP)') {
        filters.push(new Filter(' (CASE WHEN WX.ObjectID IS NULL THEN \'未激活\' WHEN PayEndDate > GETDATE() AND A.RcvTime > DATEADD(DAY, -1, GETDATE()) THEN \'正常\' WHEN PayEndDate IS NULL OR PayEndDate < GETDATE() THEN \'停机\' ELSE \'离线\' END) ', '停机', Op.Equals))
      } else if (this.wxOptionValue == '离线(WP)') {
        filters.push(new Filter(' (CASE WHEN WX.ObjectID IS NULL THEN \'未激活\' WHEN PayEndDate > GETDATE() AND A.RcvTime > DATEADD(DAY, -1, GETDATE()) THEN \'正常\' WHEN PayEndDate IS NULL OR PayEndDate < GETDATE() THEN \'停机\' ELSE \'离线\' END) ', '离线', Op.Equals))
      }
    },
    getExternalFilters(filters) {
      if (this.queryKey2 == '所有') {
        return filters
      }
    
      if (this.queryKey2 == '在线') {
        filters.push(new Filter('A.isOnline', 1, Op.Equals))
      } else if (this.queryKey2 == '离线') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == '启动') {
        filters.push(new Filter('A.Status', 1, Op.Equals))
      } else if (this.queryKey2 == '熄火') {
        filters.push(new Filter('A.Status', 0, Op.Equals))
      } else if (this.queryKey2 == '报警') {
        filters.push(new Filter('A.isAlarm', 1, Op.Equals))
      } else if (this.queryKey2 == '无报警') {
        filters.push(new Filter('A.isAlarm', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡正常') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡即将到期') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡已到期') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == 'SIM卡余额不多') {
        filters.push(new Filter('A.isOnline', 0, Op.Equals))
      } else if (this.queryKey2 == '服务已到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment(), Op.LessThanOrEqual))
      } else if (this.queryKey2 == '服务7天到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment().add(7, 'DD'), Op.LessThanOrEqual))
      } else if (this.queryKey2 == '服务30天到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment().add(30, 'DD'), Op.LessThanOrEqual))
      } else if (this.queryKey2 == '服务60天到期') {
        filters.push(new Filter('ISNULL(D.PayEndDate, DATEADD(DD, -1, GETDATE()))', miment().add(60, 'DD'), Op.LessThanOrEqual))
      }
    },
    exportHandle() {
      const filters = []

      this.searchText.length > 0
        && (filters.push(new Filter(this.searchName, this.searchText, this.searchOption)))
      this.getExternalFilters(filters)
      this.getExternalFilters2(filters)
      this.exportButtonLoading = true
      API.Export(filters, this.currentHoldId, this.hasChild).then(ret => {
        this.exportButtonLoading = false
        saveBlob(ret.data, `${this.$t('deviceDetail.name')}.xlsx`)
      })
    },
    batchImport() {
      this.excelDownLoadMethod = API.DownLoadImportDeviceDetail
      this.excelProgressMethod = API.ImprortDevicePosition
      let fn = ''
      this.$refs.importTool.import()
        .then(fileName => {
          fn = fileName
          this.$refs.importDetail.start()
          return API.ImportDeviceBeforeStart(fileName, this.currentHoldId)
        })
        .then(ret => {
          this.$refs.importDetail.open(fn, ret.data.data)
          return API.ImportDeviceFromExcelWithVIP(fn, this.currentHoldId, this.importVipMonth, this.importVipOption)
        }).then(ret => {
          console.log(ret.data)
          this.$refs.importDetail.setData(ret.data.list)
        })
    },
    refreshHandle() {
      this.pageIndex = 1
      this.queryHandle()
    },
    singlePay(item) {
      this.single.id = item.ObjectID
      this.single.serviceEndDate = item.PayEndDate
      this.single.vehicleName = item.SIM2
      this.single.trackerType = item.TrackerType
      this.dialogSinglePay = true
    },
    addToTaskList(row) {
      if (!row.VehicleID) {
        this.$message('该设备没有绑定车辆')
        return
      }
      this.addTaskCar(row.ObjectID)
    },
    send() {
      this.dialogInfoVisible = false
      this.dialogSendVisible = true
    },
    goWallet() {
      this.dialogSinglePay = false
      this.dialogInfoVisible = true
    },
    bindVehicle(row) {
      this.$refs.bindVehicle.open(row.ObjectID, row.SIM2)
    },
    getSimImg(date, money) {
      if (!this.img_sim1) {
        this.img_sim1 = require('../../assets/icon/sim1.gif')
        this.img_sim2 = require('../../assets/icon/sim2.gif')
        this.img_sim3 = require('../../assets/icon/sim3.gif')
      }
      let days = Math.floor((-miment().diff(date))/1000/3600/24)
      if (days > 60) {
        if (days < 1800) {
          return this.img_sim1
        } else {
          if (money == 0) {
            return this.img_sim3
          } else if (money < 5) {
              return this.img_sim2
          } else {
            return this.img_sim1
          }
        }
      } else {
        if (days > -1) {
          return this.img_sim2
        } else {
          return this.img_sim3
        }
      }
    },
    openIframe(options) {
      this.iframe = Object.assign(this.iframe, options)
      this.isShowDialog = true
      this.isShowDialogReal = true
    },
    closedIframe() {
      this.iframe.src = "about:blank";
      this.isShowDialogReal = false
    },
    openSimDetail(sim) {
      GetSimInfoUrl(sim).then(ret => {
        if (!ret.data.errCode) {
          console.log(ret.data)
          this.openIframe({
            width: '800px',
            height: '595px',
            class: 'el-dialog--p0',
            src: ret.data.url,
            title: `${this.$t('deviceManage.simInfo')}`
          })
        } else {
          this.$message({
            message: ret.data.errMsg,
            type: 'error'
          })
        }
      })
    },
    gotoReport(row) {
      storage.session.set(S_NEXT_REPORT_PARAM, {
        ObjectID: row.ObjectID,
        SIM2: row.SIM2,
        VehicleName: row.VehicleName,
        FunID: 11086
      })
      this.$router.push('/report/one')
    },
    goMonitor(row) {
      this.$router.push(`/online/monitor?action=addTaskList&objectid=${row.ObjectID}`)
    },
    ...mapMutations({
      'addTaskCar': 'ADD_TASK_CAR'
    })
  },
  activated() {
    if (this.currentHoldId_bak != this.currentHoldId) {
      this.currentHoldId_bak = this.currentHoldId
      this.queryHandle()
    } else if (this.hasChild !== this.currentHasChild) {
      this.hasChild = this.currentHasChild
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables-simple.scss";
.v-wrapper {
  display: flex;
  align-items: stretch;
  padding: 0 30px;
  background-color: #000416;
  .v-nav {
    width: 250px;
    flex-shrink: 0;
    overflow-y: auto;
    position: relative;
  }
  .v-body {
    width: 0;
    flex-grow: 1;
    padding: 0 0px;
    border-radius: 8px;
    box-sizing: border-box;
  }
}

.square {
  height: 14px;
  width: 14px;
  display: block;
  border-radius: 2px;
  background-color: #BDBDBD;
  &.isOnline {
    background-color: #4CAF50;
  }
}

.imgcolumn {
  img {
    vertical-align: text-top;
    margin-top: -1px;
  }
}

.add-task-car {
  margin-right: 1em;
}

.vip {
  height: 15px;
  vertical-align: sub;
  margin: 0 4px 0 4px;
}

.statusdes {
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    opacity: .84;
    color: $--color-primary;
  }
}

.sim {
  display: inline-block;
  padding: 0 4px;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    opacity: .84;
    color: $--color-primary;
  }
}

.simImg {
  vertical-align: sub;
  margin-right: 2px;
  &:hover {
    cursor: pointer;
  }
}

.modeOption {
    display: flex;
    height: auto;
    padding-top: 10px;
  li {
    padding-left: 50px;
    padding-right: 20px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    box-sizing: border-box;
    color: #A4C3E7;
    cursor: pointer;
    background-color: #001F3F;
    position: relative;
    top: 1px;
    span{
      position: absolute;
      top: 4px;
      left: 22px;
    }
    // &:first-child {
    //   border-top-left-radius: 4px;
    //   border-bottom-left-radius: 4px;
    // }
    // &:last-child {
    //   border-top-right-radius: 4px;
    //   border-bottom-right-radius: 4px;
    //   border-right: 1px solid #E4E7ED;
    // }
    &:hover {
      color: #fff;
      cursor: pointer;
    }
    &.active {
      color: #fff;
      background: url(../../assets/images/gs-dev-tit.png) no-repeat center 100% 100%;
      border:1px solid #4CB5E4;
      box-sizing: border-box;
    }
    .icon {
      font-size: 50px;
    }
  }
}
.importOptions {
  margin-top: 8px;
  .el-col:first-child {
    text-align: right;
  }
}
.iml-1{
  & ::v-deep .el-input__inner{
    border-radius: 20px;
  }
}
.i-over{
  display: block;
  width:100%;
  overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap
}
</style>
